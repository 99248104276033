import { Box } from "@mui/material";
import React from "react";
import VerticalMenu from "../../components/VerticalMenu";
import HorizontalMenu from "../../components/HorizontalMenu";
import Container from "@mui/material/Container";
import Helmet from "react-helmet";
import favicon from "../../images/favicon.ico";
import PageTitle from "../../components/PageComponents/PageTitle";
import Paragraph from "../../components/PageComponents/Paragraph";
import ParagraphTitle from "../../components/PageComponents/ParagraphTitle";
import SupportButton from "../../components/SupportButton";
import AlertInfo from "../../components/PageComponents/AlertInfo";
import BasicTable from "../../components/PageComponents/Table";

const styles = {
  pageStyles: {
    marginBottom: "100px",
  },
  pageContent: {
    display: "flex",
  },
};

const IndexPage = () => {
  const createData = (version, date, changes) => {
    return { version, date, changes };
  };
  const topRows = [
    { align: "left", title: "Version" },
    { align: "left", title: "Release date" },
    { align: "left", title: "Changes" },
  ];

  const rows = [
    createData(
      "2.0.0",
      "29/02/2024",
      "Introduced new endpoints to allow seeing, accepting, and rejecting quotes for orders. The baseUrl is changed. New order statuses have been introduced."
    ),
    createData(
      "1.1.0",
      "31/05/2023",
      "Added the data library functionality. Users can now see their data library orders list and get the details and data of a single data library order."
    ),
    createData(
      "1.0.0",
      "03/02/2022",
      "Introducing the new API to order and receive data from GLOBHE's marketplace."
    ),
  ];

  return (
    <main style={styles.pageStyles}>
      <HorizontalMenu />
      <Box style={styles.pageContent}>
        <VerticalMenu page={2} />

        <Container maxWidth="md" style={{ wordWrap: "break-word" }}>
          <PageTitle text="Version history" />
          <AlertInfo
            severity="success"
            text="The latest API version is 2.0.0."
          />
          <br />
          <Paragraph text="You can still use version 1 for ordering and receiving data. Some functionalities, like quoting and detailed order statuses are not be available in version 1. Note that support for version 1 will end by June 30th 2024" />

          <ParagraphTitle text="Looking for latest release of a version branch?" />
          <BasicTable rows={rows} topRows={topRows} />

          <Paragraph text="For questions, please contact support. We will get back to you within a working day." />
          <SupportButton />
        </Container>
      </Box>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GLOBHE&apos;s developers docs</title>
        <link rel="icon" href={favicon} />
      </Helmet>
    </main>
  );
};

export default IndexPage;
